<template>
  <div class="shop-goods-list-page">
    <en-table-layout ref="tableLayout" :tableData="pageData.data" :loading="loading"
      @selection-change="handleSelectionChange" @sort-change="handleSortChange"
      :defaultSort="{ prop: 'buynum', order: 'descending' }">

      <div slot="toolbar" style="width: 100%;">
        <div style="display: flex;">
          <el-form-item>
            <el-input v-model="params.keyWord" :placeholder="key_word === 'goods_name' ? '请输入商品名称' : '请输入商品编号'">
              <el-select slot="prepend" v-model="key_word" placeholder="请选择" style="width:110px;" size="small">
                <el-option label="商品名称" value="goods_name"></el-option>
                <el-option label="商品编号" value="goods_sn"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item label="库存">
            <el-select v-model="params.enableQuantity" placeholder="请选择" clearable style="width:110px;" size="small">
              <el-option label="全部" value></el-option>
              <el-option label="有货" :value="1"></el-option>
              <el-option label="无货" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分销价">
            <el-input size="medium" style="width: 110px;" v-model="params.low_enterprice"
              oninput="value = (value.match(/\d+/) || [''])[0]" placeholder="最低分销价"></el-input>
            <span style="margin: 0 4px;">-</span>
            <el-input size="medium" style="width: 110px;" v-model="params.high_enterprice"
              oninput="value = (value.match(/\d+/) || [''])[0]" placeholder="最高分销价"></el-input>
          </el-form-item>
          <el-form-item label="商品渠道">
            <el-select v-model="params.self_goods" placeholder="请选择" clearable style="width:110px;" size="small">
              <el-option label="全部" value></el-option>
              <el-option label="京东馆" :value="2"></el-option>
              <el-option label="自有商品" :value="1"></el-option>
              <el-option label="精选优品" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <div class="col-auto">
            <el-button @click.prevent="searchEvent" size="small" native-type="submit" type="primary">
              搜索
            </el-button>
          </div>
        </div>
        <category ref="category" @getEmitInfo="getEmitInfo" style="margin: 20px 0;" :params="params">
        </category>
        <div style="display: flex; justify-content: space-between;">
          <div>
            <el-button size="mini" type="primary" @click="exportShop">导出当前全部商品</el-button>
            <el-button style="margin-left: 5px;" size="mini" type="primary"
              @click="getGoodsGallery">批量下载商品图片</el-button>
          </div>
          <div>
            <el-button style="margin-left: 5px;" size="mini" type="danger"
              @click="$router.push({ name: 'shopCarts' })">查看购物车（{{ cartNum }}）</el-button>
          </div>
        </div>
      </div>

      <!-- 表格 -->
      <template slot="table-columns">
        <el-table-column type="selection" />

        <el-table-column label="图片" width="60" fixed="left" class-name="goods-cover-wrapper">
          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.thumbnail" class="goods-cover big" alt style="width: 300px" />
              <div slot="reference">
                <img :src="row.thumbnail" class="goods-cover" alt @click="openGoodsDetail(row)" />
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="商品名称" min-width="200" :show-overflow-tooltip="true" fixed="left">
          <template slot-scope="{row}">{{ row.goods_name?.replaceAll('amp;', '')?.replaceAll(' ', '&nbsp;')
            }}</template>
        </el-table-column>
        <el-table-column label="商品编号" width="120">
          <template slot-scope="scope">
            {{ scope.row.good_sn }}
          </template>
        </el-table-column>
        <el-table-column label="分销价" width="100" prop="enterprisePrice" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.enterprise_revise_price | unitPrice('￥') }}
          </template>
        </el-table-column>
        <el-table-column label="市场价" width="100" prop="market" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.mktprice | unitPrice('￥') }}
          </template>
        </el-table-column>
        <el-table-column label="可售库存" sortable="custom" sort-by prop="enableQuantity">
          <!-- 商品来源，0普通商品，1京东商品，2苏宁商品 -->
          <template slot-scope="scope">
            <template v-if="scope.row.goods_source == 1">
              <template v-if="scope.row.enable_quantity > 0">有货</template>
              <template v-else>无货</template>
            </template>
            <template v-else>
              {{ scope.row.enable_quantity }}件
            </template>
          </template>
        </el-table-column>
        <el-table-column label="已购件数" width="120" prop="buynum" sortable="custom">
          <template slot-scope="scope">{{ scope.row.buy_count }}</template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="230">
          <div slot-scope="scope" style="display: flex;">
            <el-input-number size="mini" :min="1"
              :max="scope.row.goods_source == 1 ? scope.row.enable_quantity ? 9999 : 0 : scope.row.enable_quantity > 9999 ? 9999 : scope.row.enable_quantity"
              v-model="scope.row.number"></el-input-number>
            <el-button style="margin-left: 5px;" size="mini" type="primary"
              @click="addCarts(scope.row)">加入购物车</el-button>
          </div>
        </el-table-column>
      </template>


      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-size="pageData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="pageData.data_total" />
    </en-table-layout>
    <!-- 商品详情弹窗 -->
    <el-dialog custom-class="goodsclassify" :visible.sync="dialogVisible" :close-on-click-modal="false"
      :close-on-press-escape="false" append-to-body width="1150px">
      <div slot="title" class="goods-picker-title">
        <span></span>
        商品详情
      </div>
      <goodsPurchaseclassify v-if="dialogVisible" :formData="goodsclassifyData" :cartNum="cartNum">
      </goodsPurchaseclassify>
    </el-dialog>
  </div>
</template>

<script>
import { getShopGoodsList } from '@/api/goods'
import { getGoodsGallery, getCarts, addCarts, putCarts, deleteCarts, checkedCarts } from '@/api/shopGoodsPurchase'
import { Foundation } from "@/../ui-utils";
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import category from './components/category'
import goodsPurchaseclassify from "@/components/GoodsClassify/goodsPurchaseclassify";
export default {
  components: {
    EnTableLayout,
    category,
    goodsPurchaseclassify
  },
  data () {
    return {
      cartNum: 0,
      key_word: 'goods_name',
      dialogVisible: false,
      goodsclassifyData: {},
      pageData: {
        data: []
      },
      loading: false,
      params: {
        page_no: 1,
        page_size: 20,
        low_enterprice: '',//分销价小值
        high_enterprice: '', // 分销价大值
        enableQuantity: '', // 1 有货 2 无货
        keyWord: '',
        shop_goods_status: 1,// 已上架
        sort: 'buynum_desc',
        self_goods: ''
      },
      idsList: []
    }
  },
  created () {
    this.getData()
    this.getShopGoodsList()
    this.getCarts()
  },
  methods: {
    getData () { },
    getEmitInfo (emitInfo) {
      this.params.category_path = emitInfo
      this.getShopGoodsList()
    },
    // 排序功能
    handleSortChange ({
      prop,
      order
    }) {
      if (order) {
        this.params.sort = `${prop}_${order.includes("asc") ? "asc" : "desc"}`;
      } else {
        this.params.sort = "";
      }
      this.getShopGoodsList();
    },
    // 筛选功能

    handleSelectionChange (val) {
      let ids = []
      val.forEach(item => {
        ids.push(item.goods_id)
      })

      this.idsList = ids
    },
    // 搜索
    searchEvent () {
      // 最低分销价的值要小于最高分销价的值
      if (this.params.low_enterprice && this.params.high_enterprice && this.params.low_enterprice > this.params.high_enterprice) {
        this.$message.error('最低分销价不能大于最高分销价')
        return
      }
      this.$refs.category.getCategorys()
      const arr = ['goods_name', 'goods_sn']
      this.params[this.key_word] = this.params.keyWord
      this.params[arr[+!arr.findIndex(item => item === this.key_word)]] = undefined
      this.params.page_no = 1
      this.getShopGoodsList()
    },
    openGoodsDetail (row) {
      this.dialogVisible = true
      console.log(row);
      this.goodsclassifyData = {
        from: "",
        goods_id: row.goods_id,
        good_sn: row.good_sn,
        revise_price: row.revise_price,
        shop_profit: row.shop_profit,
        islogin: this.MixinIsFormEnterprise,//是否单独登录
        distribution_rate: row.distribution_rate,
        sku_id: row.sku_id,
        addCarts: this.addCarts,
      }
    },
    async getCarts () {
      const res = await getCarts()
      if (!res.data.list) return this.cartNum = 0
      this.cartNum = res?.data?.list?.[0]?.all_list?.reduce((a, b) => a + b.num, 0) ?? 0
    },
    // 加入购物车
    async addCarts (row) {
      let res = {}
      try {
        const { sku_id, number: num } = row
        res = await addCarts({ sku_id, num })
        this.$message.success(res.message)
        this.getCarts()
      } catch (error) {
      } finally {
      }
    },
    // 每页数量变更
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.getShopGoodsList();
    },
    // 页数变更
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.getShopGoodsList()
    },
    // 获取商品列表
    async getShopGoodsList () {

      const res = await getShopGoodsList(this.params)
      if (res.data.length === 0 && res.page_no > 1) {
        this.params.page_no--;
        return this.getShopGoodsList();
      }
      if (res?.data?.length) res.data.forEach(item => item.number = 1)
      this.pageData = res
    },
    // 导出全部商品
    exportShop () {
      this.loading = true;
      const params = { ...this.params }
      // this.params.page_no = 1;
      // this.params.page_size = 99999;
      params.page_no = 1;
      params.page_size = 99999;
      getShopGoodsList(params).then((response) => {
        this.loading = false;
        // 商品来源，0普通商品，1京东商品，2苏宁商品
        const json = {
          sheet_name: this.getDate(),
          sheet_values: response.data.map((item) => ({
            商品名称: item.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;'),
            商品编号: item.good_sn,
            分销价: Foundation.formatPrice(item.enterprise_revise_price),
            市场价: Foundation.formatPrice(item.mktprice),
          })),
        };
        this.MixinExportJsonToExcel(json, this.getDate());
      }, err => {
        this.loading = false;
      });
    },
    //导出勾选选项图片
    getGoodsGallery () {
      if (!this.idsList.length) {
        this.$message.error('请勾选商品')
        return
      }
      const checkedGoodsId = this.idsList
      const params = {
        checkedGoodsId,
        replace_goods: 0,
        ...this.params
      }
      params.page_no = 1;
      params.page_size = 99999;

      getGoodsGallery(params).then(url => {
        console.log(url, 666)
        if (!url) return
        this.$message.success('导出成功。')
        const a = document.createElement("a");
        a.href = url;
        a.click();
      })
    },
    // 获取年月日时分秒
    getDate () { // 获取当前时间
      const addZeroForNum = (num) => {
        return num < 10 ? '0' + num : num
      }
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours(); // 获取当前小时数(0-23)
      const minute = date.getMinutes(); // 获取当前分钟数(0-59)
      const second = date.getSeconds(); // 获取当前秒数(0-59)

      const time = `${year}${addZeroForNum(month)}${addZeroForNum(day)}${addZeroForNum(hour)}${addZeroForNum(minute)}${addZeroForNum(second)}`;
      return time;
    }




  }
}
</script>
<style lang="scss" scoped>
/deep/ .goodsclassify .el-dialog__header {
  padding: 0;
}

/deep/ .goodsclassify {
  .el-dialog__body {
    padding: 0 20px;
  }
}

.goods-picker-title {
  border-bottom: 1px solid #ccc;
  height: 51px;
  line-height: 51px;
  text-align: left;
  background-color: rgba(224, 228, 238, 1);
  font-weight: 600;
  border-radius: 5px;
}

.goods-picker-title span {
  background-color: #1a43a9;
  width: 4px;
  height: 14px;
  display: inline-block;
  margin-right: 14px;
  margin-bottom: 3px;
}
</style>