<template>
  <div class="content">
    <!-- 一级分类 -->
    <div class="category">
      <div class="title">一级分类:</div>
      <div class="subtitle">
        <div v-for="(info, index) in categoryData.data" :key="info.category_id"
          :class="['name', categoryData.selectIndex === index ? 'select' : '']"
          @click="_ => (getFirstchildren(info.category_id), categoryData.selectIndex = index)">
          {{ info.name }}</div>
      </div>
    </div>
    <!-- 二级分类 -->
    <div class="category" v-show="categoryData.selectIndex">
      <div class="title">二级分类:</div>
      <div class="subtitle">
        <div v-for="(info, index) in categoryData2.data" :key="info.category_id"
          :class="['name', categoryData2.selectIndex === index ? 'select' : '']"
          @click="_ => (getChildren(info.children), categoryData2.selectIndex = index)">{{ info.name }}</div>
      </div>
    </div>
    <!-- 三级分类 -->
    <div class="category" v-show="categoryData.selectIndex && categoryData2.selectIndex">
      <div class="title">三级分类:</div>
      <div class="subtitle">
        <div v-for="(info, index) in categoryData3.data" :key="info.category_id"
          :class="['name', categoryData3.selectIndex === index ? 'select' : '']"
          @click="_ => (emit(), categoryData3.selectIndex = index)">{{ info.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCategorys, getFirstchildren } from '@/api/shopGoodsPurchase'
import { strict } from 'assert';
import { number } from 'echarts';
export default {
  name: 'ManagerOneClickDeliveryCategory',
  props: {
    params: {
      type: Object,
      default: undefined
    },
  },
  data () {
    return {
      categoryData: {
        title: '一级分类',
        data: [],
        selectIndex: 0
      },
      categoryData2: {
        title: '二级分类',
        data: [],
        selectIndex: 0
      },
      categoryData3: {
        title: '三级分类',
        data: [],
        selectIndex: 0
      },
    };
  },

  mounted () {
    this.getCategorys()
  },

  methods: {
    async getCategorys (parent_id = 0) {
      try {
        this.categoryData.selectIndex = 0
        this.emit()
        if (parent_id == 0) this.categoryData2.data = [], this.categoryData3.data = []
        const arr = [{ name: '全部', category_id: 0 }]
        const res = await getCategorys(parent_id, this.params)
        arr.push(...res)
        this.categoryData.data = arr
      } catch (error) {
        console.log(error)
      }
    },
    async getFirstchildren (first_id = 0) {
      try {
        this.categoryData2.data = [], this.categoryData3.data = []
        if (!first_id) return this.emit()
        const arr = [{ name: '全部', category_id: 0 }]
        const res = await getFirstchildren(first_id, this.params)
        arr.push(...res)
        this.categoryData2.data = arr
        this.categoryData2.selectIndex = 0
        this.emit()
      } catch (error) {
        console.log(error)
      }
    },
    getChildren (res) {
      try {
        this.categoryData3.data = []
        if (!res) return this.emit()
        const arr = [{ name: '全部', category_id: 0 }]
        arr.push(...res)
        this.categoryData3.data = arr
        this.categoryData3.selectIndex = 0
        this.emit()
      } catch (error) {
        console.log(error)
      }
    },
    emit () {
      this.$nextTick(() => {
        const level = this.categoryData.data[this.categoryData.selectIndex]
        const level2 = this.categoryData2.data[this.categoryData2.selectIndex]
        const level3 = this.categoryData3.data[this.categoryData3.selectIndex]
        const category_path = level3?.category_path ? level3.category_path : level2?.category_path ? level2?.category_path : level?.category_path
        console.log(11)
        this.$emit('getEmitInfo', category_path)
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.select {
  color: #b8741a;
}

.content {
  .category {
    display: flex;
    align-items: flex-start;

    .subtitle {
      flex: 1;
      display: flex;
      flex-wrap: wrap;

      .name {
        margin-left: 20px;
        // 鼠标放上去是个小手
        cursor: pointer;
      }
    }
  }
}
</style>